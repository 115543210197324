import React from 'react';
import './App.css';
import { ThemeProvider, theme, CSSReset } from '@chakra-ui/core';
import { Routes } from './app/routes/routes';
import axios from 'axios';

function App(props) {
	

	return (
		<ThemeProvider theme={theme}>
			<CSSReset />
			<Routes theme={theme} />
		</ThemeProvider>
	);
}

export default App;
