import React, { Component } from 'react';
import Results from '../../components/Results/Results';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Box, Spinner } from '@chakra-ui/core';
class ResultsContainer extends Component {
	state = {
		loading: true,
		results: [],
	};

	componentDidMount() {
		// console.log(this.props.location.state.img.split(",")[1])
		(!this.props.location.state.results)?(
		axios
			.post(
				'https://kyc.platform.xane.ai/dev/predictions/id',
				// {
				// 	image: this.props.location.state.img.split(",")[1],
				// 	variant_code: 'SSR4AL1',
				// },
			 this.props.location.state.img.split(",")[1],
				{
					headers: {
            'Content-Type': 'text/plain;charset=utf-8'
				// 		'x-auth': 'b9d7e3b658ed46b0b66c1ffb0fbb04532460023701324455bb7044fb0f07548c',
				// 		// 'x-key': 'eecc2f7ce69040d9844d52bdb46cf167bdc878e8292d40aaaadea1f37db4e465',
				// 		'x-platform': 1,
				// 		'x-device-id': 'device',
				// 		'x-origin': 1,
				// 		'x-version': 1,
					},
				}
			)
			.then((response) => {
				// let filteredResponses = response.data.data.predictions[0].intent.replace(/_/g, ' ').toUpperCase();
				// let filteredResponses = response.data.data.interaction.predictions.splice(0, 3);
        let apiResponse = response.data;
				this.setState({
					results: apiResponse,
					loading: false,
				});
			})):(

				this.setState({
					results: this.props.location.state.results,
					loading: false,
				})
			)
		// axios
		//   .post(
		//     "https://xanegin.xane.ai/query",
		//     {
		//       image_url: this.props.location.state.img,
		//       variant_code: "abc",
		//     },
		//     {
		//       headers: {
		//         "x-key":
		//           "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
		//       },
		//     }
		//   )
		//   .then((response) => {
		//     console.log(response.data.data.predictions);
		//     let arr = [];
		//     let obj = {};
		//     obj.type_of_tyre = "Car";
		//     obj.tyre_id = parseInt(Math.floor(Math.random() * 1000));
		//     obj.detect_identified =
		//       response.data.data.predictions[0].intent == "normal" ? "No" : "Yes";
		//     obj.detect_classification = response.data.data.predictions[0].intent;
		//     obj.confidence = response.data.data.predictions[0].confidence;
		//     obj.inspection_summary =
		//       response.data.data.predictions[0].intent == "normal"
		//         ? "Accepted"
		//         : response.data.data.predictions[0].intent == "sidewall"
		//         ? "Rejected"
		//         : "To be Repaired";
		//     arr = [...arr,obj];

		//     this.setState({ results: arr, loading: false });
		//   });
	}

	render() {
		console.log(this.props.location.state.img)
		const { results } = this.state;
		return this.state.loading ? (
			<Box display="flex" alignItems="center" justifyContent="center" backgroundColor="#2b2b2b" h="100vh">
				<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#2792BA" size="xl" />
			</Box>
		) : (
			<div>
				<Results image={this.props.location.state.img} results={results ? results : []} />
			</div>
		);
	}
}

export default withRouter(ResultsContainer);
