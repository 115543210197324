import React from "react";
import "../../components/Home/styles.css";
import { Box, Input, IconButton, Spinner } from "@chakra-ui/core";
import { FaPaperPlane } from "react-icons/fa";
import { Widget } from "react-chat-widget";
import { Card } from "../custom/Card";
import "../Home/styles.css";

import "react-chat-widget/lib/styles.css";
export default function Chat(props) {
  return (
    // <div className="App">
    //   <Widget han/>
    // </div>
    <div
      style={{
        backgroundColor: "#2b2b2b",
        height: window.innerHeight,
        flexDirection: "column",
      }}
    >
      {props.loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#2792BA"
          size="xl"
        />
      ) : (
        <div
          style={{
            flexDirection: "column",
            maxHeight: "600px",
            overflowY: "scroll",
            justifyContent: "space-between",
            backgroundColor: "#2b2b2b",
          }}
        >
          <Box d="flex" flexDirection="column" alignItems="center" p="1em">
            {props.query && (
              <div style={{ alignSelf: "flex-end" }}>
                <div
                  style={{
                    color: "white",

                    backgroundColor: "#2792BA",

                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                    {props.query}
                  </p>
                </div>
              </div>
            )}
            {/* <div style={{ color: "#2792BA", alignSelf: "flex-end" }}>
              
            </div> */}
            {props.results &&
              props.results.map((result, i) => {
                return (
                  <Card
                    data={result}
                    key={result.intent.toString()}
                    isVoice={false}
                  />
                );
              })}
          </Box>
        </div>
      )}
      <div
        style={{
          position: "fixed",
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          bottom: 10,
          left: 20,
          width: "90%",
          alignItems: "center",
          backgroundColor: "#2b2b2b",
        }}
      >
        <Input
          placeholder="Enter your message"
          size="md"
          value={props.message}
          onChange={(e) => props.onChange(e)}
          borderRadius={50}
          onKeyDown={(e) => props._handleKeyDown(e)}
        />
        <IconButton
          colorScheme="blue"
          aria-label="Call Sage"
          fontSize="20px"
          icon={FaPaperPlane}
          marginLeft={5}
          onClick={() => props.fetchResponse(props.message)}
        />
      </div>
    </div>
  );
}
