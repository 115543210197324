import React from "react";
import "./styles.css";
import { Box, Image } from "@chakra-ui/core";
import { FaCamera, FaMicrophone, FaRocketchat, FaUpload } from "react-icons/fa";
import { withRouter } from "react-router-dom";
const Home = (props) => {
  return (
    <div className="main">
      <div
        className="camera"
        onClick={() => {
          props.history.push("/camera");
        }}
      >
        <Box as={FaCamera} size="36px" color="#2792BA" />
      </div>
      <div
        className="files"
        onClick={() => {
          props.history.push("/upload-file");
        }}
      >
        <Box as={FaUpload} size="36px" color="#2792BA" />
      </div>
      <div
        className="mic"
        onClick={() => {
          props.history.push("/voice");
        }}
      >
        <Box as={FaMicrophone} size="36px" color="#2792BA" />
      </div>
      {/* <div
        className="mic"
        
      >
        <Box as={FaMicrophone} size="36px" color="#2792BA" opacity={0.3}/>
      </div> */}
      <div
        className="text"
        onClick={() => {
          props.history.push("/text");
        }}
      >
        <Box as={FaRocketchat} size="36px" color="#2792BA" />
      </div>
      <Box className="xane_icon">
        <Image src={require("./xane.png")} alt="Segun Adebayo" size="96px" />
      </Box>
    </div>
  );
};

export default withRouter(Home);
