import React, { useState } from "react";
import * as ReactDOM from "react-dom";
import { Box, Spinner } from "@chakra-ui/core";
import { Heading } from "@chakra-ui/react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { withRouter } from "react-router-dom";
import { FaUpload } from "react-icons/fa";
import axios from 'axios';
import "./file.css";


const File = (props) => {
  const [selectedFile, setSelectedFile] = useState(0);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [rowImage, setRowImage] = useState(null);
  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    getBase64(event.target.files[0]).then(
      (data) => {
        setRowImage(data)
        setImage(data.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '').replace('data:application/pdf;base64,', ''))
      });
    setSelectedFile(event.target.files[0]);
    console.log(event.target.files[0])
    // document.getElementById('upload-btn').disabled = false;
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  // On file upload (click the upload button)
  const onFileUpload = () => {

// console.log(image)
    // props.history.push({pathname:"/results",state:{img:formData}});
setLoading(true)
    axios
			.post(
				'https://kyc.platform.xane.ai/dev/predictions/id',
				// 'https://60dd6037878c890017fa27f3.mockapi.io',
				// {
				// 	image: this.props.location.state.img.split(",")[1],
				// 	variant_code: 'SSR4AL1',
				// },
        image,
				{
					headers: {
            'Content-Type': 'text/plain;charset=utf-8'
				// 		'x-auth': 'b9d7e3b658ed46b0b66c1ffb0fbb04532460023701324455bb7044fb0f07548c',
				// 		// 'x-key': 'eecc2f7ce69040d9844d52bdb46cf167bdc878e8292d40aaaadea1f37db4e465',
				// 		'x-platform': 1,
				// 		'x-device-id': 'device',
				// 		'x-origin': 1,
				// 		'x-version': 1,
					},
				}
			)
			.then((response) => {
				// let filteredResponses = response.data.data.predictions[0].intent.replace(/_/g, ' ').toUpperCase();
				// let filteredResponses = response.data.data.interaction.predictions.splice(0, 3);
        let apiResponse = response.data;
        setResults(apiResponse)
        setLoading(false)
        console.log(apiResponse)
        props.history.push({pathname:"/results",state:{results:apiResponse, img:rowImage }});
			});

  };

  // File content to be displayed after
  // file upload is complete
  const fileData = () => {
    if (selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>
        </div>
      );
    }
  };

  return   (!loading)?(
    <>
      <Heading
        color="#2792BA"
        alignSelf="center"
        justifyContent="center"
        display="flex"
        padding="1em"
        className="heading"
        position="relative"
        top="40vh"
      >
        Upload File
      </Heading>
      <Box
        color="#2792BA"
        alignSelf="center"
        justifyContent="center"
        display="flex"
        padding="1em"
        className="heading"
        position="relative"
        top="40vh"
      >
        <div>
          <div className="FileDiv">
            <input type="file" onChange={onFileChange} id="file" accept="image/png,image/jpeg"/>
          </div>
          {fileData()}
          <div className="btnDiv">
            <Button id="upload-btn" className="pulse" onClick={onFileUpload} isDisabled={(!image)?"true":""} >
              {/* <button class="fill">Fill In</button>
              <button class="pulse">Pulse</button>
              <button class="close">Close</button>
              <button class="raise">Raise</button>
              <button class="up">Fill Up</button>
              <button class="slide">Slide</button> */}
              Upload!
            </Button>
          </div>
        </div>
      </Box>
    </>
  ):<><Box display="flex" alignItems="center" justifyContent="center" backgroundColor="#2b2b2b" h="100vh">
  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#2792BA" size="xl" />
</Box></>;
};

//  export default File;

export default withRouter(File);
