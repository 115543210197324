import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Image } from "@chakra-ui/core";
import { Card } from "../custom/Card";
import "./result.css";
const Results = (props) => {
  let title, content;
  title = 'Detection unsuccessful';
  content = 'Please try again.';

  try {
    if(props.results.data.type) {
      title = props.results.data.type;

      if(props.results.data.fields && props.results.data.fields.length !== 0) {
        content = props.results.data.fields;
      }
    }
  } catch(e) {
    console.log(e);
  }

  let _data = {
    title: title,
    content: content
  }
//  console.log(props.results)
  return (
    <div
      style={{
        backgroundColor: "#2b2b2b",
        minHeight: "100%",
        paddingBottom: "1em",
      }}
    >

<Box
      width={[
        "90%", // 0-30em
        "50%", // 30em-48em
        "25%", // 48em-62em
        "40%", // 62em+
      ]}
     
      display="flex"
      justifyContent="center"
      borderWidth="1px"
      rounded="md"
      overflow="hidden"
      border="1px solid grey"
      p="3"
      margin= "auto"
      marginTop= "5px"
    
    >

    <Image src={props.image} size="100%" boxShadow= "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"/>
    </Box>
      
      <Box
        color="#2792BA"
        alignSelf="center"
        justifyContent="center"
        display="flex"
        padding="1em"
        className="heading"
      >
        <span>Scan results</span>
      </Box>
      <Box d="flex" flexDirection="column" alignItems="center" p="1em">
        {
          <Card data={_data} key={content} isVoice={false}/>
        }{" "}
      </Box>
    </div>
  );
};

export default withRouter(Results);
